import React, { Component } from 'react';
import {
    MDBBtn,
    MDBView,
    MDBMask,
    MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter,
    MDBContainer, MDBRow, MDBCol
} from 'mdbreact';
import VideoPage from '../../components/VideoPage/videoPage';

import './cardDisplay.css';

class CardDisplay extends Component {

    state = {
        modal: false,
        videoJsOptions: {}
    }

    lauchVideo = (video) => {
        //     ],
        // }

        const videoJsOptions = {
            autoplay: false,
            playbackRates: [0.5, 1, 1.25, 1.5, 2],
            width: 720,
            height: 300,
            controls: true,
            controlBar: {
                children: [
                    'playToggle',
                    'progressControl',
                    'volumePanel',
                    'qualitySelector',
                    'fullscreenToggle',
                ],
            },
            sources: [
                {
                    src: video.source[0].src,
                    type: 'video/mp4',
                    label: 'SD - 480p',
                    selected: this.props.quality === 'SD - 480p' ? true : false
                },
                {
                    src: video.sourceHD[0].src,
                    type: 'video/mp4',
                    label: 'HD - 720p',
                    selected: this.props.quality === 'HD - 720p' ? true : false
                }
                // {
                //     src: video.sourceTOTO[0].src,
                //     type: 'video/mp4',
                //     label:'TOTO',
                //     selected: this.props.quality === 'TOTO' ? true : false
                // }
            ],
        }
        this.setState((oldState, props) => {
            return {
                modal: !oldState.modal,
                videoJsOptions: videoJsOptions
            }
        });
    }

    toggle = () => {
        this.setState((oldState, props) => {
            return {
                modal: !oldState.modal,
                videoJsOptions: oldState.modal ? {} : oldState.videoJsOptions
            }
        });
    }

    downloadMovieDesciptionFile = (url) => {
        fetch(url)
            .then(response => {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'employees.mp4';
                    a.click();
                });
            });
    }



    render() {
        const regex = /(<([^>]+)>)/ig;
        const catArray = this.props.video.categories;
        console.log(catArray);

        return (
            <>
                <a>
                    <MDBView hover zoom
                        onClick={() => { this.lauchVideo(this.props.video) }}
                        className="z-depth-1">
                        <img src={this.props.video.imgMin} className="img-fluid" alt={this.props.video.title} />
                        <MDBMask overlay="blue-strong" className="flex-center" style={{ cursor: 'pointer' }}>
                            <p className="white-text"
                                onClick={() => { this.lauchVideo(this.props.video) }}>
                                {this.props.lang === 'fr' ? 'Regarder' : 'Watch now'}
                            </p>
                        </MDBMask>
                    </MDBView>
                </a>

                <h6 className="pt-3 pb-0 cyan-text text-uppercase font-weight-bold"> {this.props.video.title} </h6>
                <h6 className="pb-0 grey-text font-italic font-small">{this.props.video.categories.join(", ")} </h6>
                <hr className="cyan p-0 position-relative ml-0" style={{ width: "30%", height: 2 }} />

                {this.props.video.description ?
                    // <p dangerouslySetInnerHTML={{ __html: this.props.video.description.split(' ').slice(0, 25).join(' ') + ' ...' }} className="small text-justify"></p> :
                    <p className="small text-justify"> {(this.props.video.description.split(' ').slice(0, 25).join(' ') + ' ...').replace(regex, '')} </p> :
                    null
                }
                <MDBBtn color="primary uppercase" onClick={() => { this.lauchVideo(this.props.video) }}>{this.props.lang === 'fr' ? 'Regarder' : 'Watch now'}</MDBBtn>
                {/* modal */}
                <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="fluid" fullHeight position="top" className="p-0">
                    <MDBModalHeader className="transparent border-0 text-white position-absolute w-100" toggle={this.toggle} style={{ background: 'rgba(255,255,255,0)', top: 0, left: 0, zIndex: 9999 }}></MDBModalHeader>
                    <MDBModalBody className="unique-color-dark p-0">
                        <VideoPage videoJsOptions={this.state.videoJsOptions} lang={this.props.lang} handleSwitchQuality={this.props.handleSwitchQuality} />
                        <MDBContainer className="p-0">
                            <MDBRow>
                                <MDBCol size="12" className="mt-3 mb-3 pt-3 pb-3">
                                    <h1 className="text-center text-md-left cyan-text"><i className="fas fa-film"></i> {this.props.video.title} </h1>
                                    <p className="small font-italic font-weight-lighter grey-text">
                                        {this.props.video.categories.map((categorie) => {
                                            return (
                                                <span className="span-desc">{ categorie } </span>
                                            );
                                        })}
                                    </p>
                                    <hr className="cyan p-0 position-relative ml-0" style={{ width: "10%", height: 3 }} />
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className=" mt-4 mb-4">
                                <MDBCol size="4">
                                    <img src={this.props.video.imgFull} className="img-fluid" alt="" />
                                </MDBCol>
                                <MDBCol size="8">
                                    {this.props.video.description ?
                                        < div className="text-justify" dangerouslySetInnerHTML={{ __html: this.props.video.description }}></div> :
                                        null
                                    }
                                    {this.props.video.download ?
                                        <a href={this.props.video.download} className="btn btn-primary mt-3" target="_blank">{this.props.lang === 'fr' ? 'Télécharger la fiche' : 'Download the sheet'}</a> :
                                        null
                                    }

                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBModalBody>
                    <MDBModalFooter className="unique-color-dark">
                        <MDBBtn color="deep-orange" onClick={this.toggle}>{this.props.lang === 'fr' ? 'Fermer' : 'Close'}</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </>
        );
    }
}

export default CardDisplay;