import React, { Component } from 'react';
import { Parallax } from "react-parallax";
import './header.css';
import {
    MDBContainer,
    MDBMask,
    MDBCol, MDBRow,
    MDBAnimation
} from 'mdbreact';

class SubHeaderHome extends Component {
    public_folder = process.env.PUBLIC_URL;
    bool = true;
    render() {
        return (
            <MDBContainer fluid className="p-0">
                <MDBAnimation type="fadeIn">
                    <Parallax bgImage={this.public_folder + '/imgs/bg-home.jpg'} strength={-160}>
                        <div className="bg">
                            <MDBMask overlay="black-light" className="flex-center flex-column text-white text-center">
                                <MDBAnimation type="fadeInDown" delay="0.6s">
                                    <h5 className="cyan-text">
                                        {this.props.nbMovies} {this.props.lang === 'fr' ? 'Films & Animations' : 'Movies & Animations'} </h5>
                                </MDBAnimation>
                                <MDBAnimation type="fadeInUp" delay="0.3s">
                                
                                <h1 className="font-weight-bold h2-responsive">
                                    {this.props.lang === 'fr' ? 'Océanographie et Hydrologie spatiale' :
                                'Space Oceanography & Hydrology'}
                                </h1>
                                </MDBAnimation>
                                <MDBContainer>
                                    <MDBRow>
                                        <MDBCol size="12">
                                            <MDBAnimation type="fadeIn" delay="0.8s" duration="2s">
                                                {this.props.lang === 'fr' ?
                                                    <p className="">
                                                        Depuis 1992, les satellites altimétriques observent les océans, surveillent le niveau des mers et la circulation océanique. De nombreuses applications opérationnelles ont été développées. Ces satellites permettent également la surveillance du niveau des fleuves et des lacs. Une nouvelle science est née : l’hydrologie spatiale. Le futur satellite franco-américain SWOT, lancé en 2022, ouvrira la voie à une gestion globale des ressources en eau de notre planète. 
                                                    </p> :
                                                    <p className="">
                                                        Altimetry satellites have been surveying the world’s oceans since the early 1970s, using a range-measurement technique that was employed for the first time by U.S. satellites, notably Seasat launched in 1978. <br />
                                                        Since then, each new generation of satellites—TOPEX/Poseidon and then Jason—has increased spatial resolution by continuously improving the quality of instruments, data processing and geophysical corrections needed to observe the signature of the ocean circulation.
                                                    </p>}
                                            </MDBAnimation>
                                        </MDBCol>
                                    </MDBRow>
                                </MDBContainer>
                            </MDBMask>
                        </div>
                    </Parallax>
                </MDBAnimation>
            </MDBContainer >
        );
    }
}

export default SubHeaderHome;
