import React, { Component } from 'react';
import './header.css';
import {
    MDBNavbar, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink,
    MDBCol,
    MDBAnimation
} from 'mdbreact';

class Header extends Component {

    public_folder = process.env.PUBLIC_URL;

    state = {
        isOpen: false,
        activeRoute: '/',
        lang: 'fr'
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    setActiveRoute(route) {
        this.setState({ activeRoute: route });
    }

    toggleLang = (lang) => {
        document.title = lang === 'fr' ? 'Océanographie spatiale' : 'Space Oceanography';
        this.lang = lang;
        this.props.updateLang(lang);
    }

    bool = true;

    render() {
        return (
            <header>
                <MDBAnimation type="fadeInDownBig" className="position-fixed w-100 fixed-top">
                    <MDBNavbar color="elegant-color-dark" dark expand="md" fixed="top">
                        <MDBCol size="6" md="3" lg="2" href="/" >
                            <a href="/">
                                <img src={this.public_folder + "/imgs/logo-picto.svg"} className="img-fluid d-block d-md-none" style={{ 'width': '30%', maxHeight: 40 }} alt="" />
                                <img src={this.public_folder + "/imgs/logo.png"} className="img-fluid d-none d-md-block" style={{ 'width': '100%', maxHeight: 40 }} alt="" />
                            </a>
                        </MDBCol>
                        <MDBNavbarToggler onClick={this.toggleCollapse} />
                        <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                            <MDBNavbarNav left>

                            </MDBNavbarNav>
                            <MDBNavbarNav right>
                                <MDBNavItem className="mr-md-2" active={this.state.activeRoute === '/' ? true : false} onClick={() => {
                                    this.setActiveRoute('/');
                                }}>
                                    <MDBNavLink to="/">{this.props.lang === 'fr' ? 'Explorer' : this.props.lang === 'en' ? 'Explore' : ''}</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem className="mr-md-2" active={this.state.activeRoute === '/credits' ? true : false} onClick={() => {
                                    this.setActiveRoute('/credits');
                                }}>
                                    <MDBNavLink to="/credits">{this.props.lang === 'fr' ? 'Crédits' : this.props.lang === 'en' ? 'Credits' : ''}</MDBNavLink>
                                </MDBNavItem>
                                <MDBNavItem onClick={() => { this.props.lang === 'fr' ? this.toggleLang('en') :this.toggleLang('fr')}}>
                                    <MDBNavLink to="#">
                                        {/* language switch */}
                                        {this.props.lang === 'fr' ? 
                                        <img src={this.public_folder + "/imgs/en-flag.png"} className="img-fluid" style={{ 'width': 30, 'height': 20 }} alt="" /> :
                                        <img src={this.public_folder + "/imgs/fr-flag.png"} className="img-fluid" style={{ 'width': 30, 'height': 20 }} alt="" />
                                        }
                                    </MDBNavLink>
                                </MDBNavItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBNavbar>
                </MDBAnimation>
            </header>
        );
    }
}

export default Header;
