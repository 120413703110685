import React, { Component } from 'react';

import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBFormInline } from "mdbreact";
import { StickyContainer, Sticky } from 'react-sticky';

import SubHeaderHome from '../Header/subHeaderHome';
import Filters from "../Filters/filters";
import Display from '../Display/display';
import SliderHome from '../SliderHome/sliderHome';
import PreFooter from '../Footer/preFooter';
import './main.css';

class Main extends Component {
    state = {
        categories: [],
        videos: [],
        videosEn: [],
        result: [],
        searchValue: ''
    }
    render() {
        return (
            <>
                <SubHeaderHome nbMovies={this.props.nbTotalVideos} lang={this.props.lang} />

                <MDBContainer>
                    <StickyContainer>
                        <MDBRow className="text-white">
                            <MDBCol md="3" className="stylish-color-dark">
                                <div className="d-none d-md-block">
                                    <Sticky bottomOffset={50} disableCompensation>
                                        {({
                                            style,

                                            // the following are also available but unused in this example
                                            isSticky
                                        }) => (
                                            <header style={{ ...style, marginTop: isSticky ? 50 : 0 }}>
                                                {
                                                    <>
                                                        <hr className="grey" />
                                                        <h5>{this.props.lang === 'fr' ? 'Catégories' : 'Categories'}</h5>
                                                        <hr className="grey" />
                                                        <Filters categories={this.props.categories} setCategories={this.props.setCategories} lang={this.props.lang} />
                                                        <hr className="grey" />
                                                        <MDBFormInline>
                                                            <div className="md-form d-flex align-items-center justify-content-center my-0 w-100">
                                                                <MDBIcon icon="search" className="grey-text" />
                                                                <input className="form-control form-control-sm ml-1 w-100 text-white" type="text" placeholder={this.props.lang === 'fr' ? 'Rechercher un film' : 'Search movie'} aria-label='Search' value={this.props.searchValue} onChange={this.props.handleSearchValue} />
                                                            </div>
                                                            <p className="p-0 p-md-2 font-italic font-weight-lighter small grey-text text-center text-md-right w-100">{this.props.videos.length} {this.props.lang === 'fr' ? 'Résultats' : 'Results'}</p>
                                                        </MDBFormInline>
                                                    </>
                                                }
                                            </header>
                                        )}
                                    </Sticky>
                                </div>
                                <div className="d-block d-md-none">
                                    <hr className="grey" />
                                    <h5>{this.props.lang === 'fr' ? 'Catégories' : 'Categories'}</h5>
                                    <hr className="grey" />
                                    <Filters categories={this.props.categories} setCategories={this.props.setCategories} lang={this.props.lang} />
                                    <hr className="grey" />
                                </div>
                            </MDBCol>
                            <MDBCol md="9">
                                <Display videos={this.props.videos} searchValue={this.props.searchValue} handleSearchValue={this.props.handleSearchValue} nbMovies={this.props.videos.length} lang={this.props.lang} />
                            </MDBCol>
                        </MDBRow>
                    </StickyContainer>
                </MDBContainer>
                <SliderHome lang={this.props.lang} />
                <PreFooter />
            </>
        );
    }
}

export default Main;