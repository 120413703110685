import React from 'react';
import classes from './checkbox.module.css';

const Checkbox = (props) => {
    return (
        <>
            <div className={classes.checkboxCustom}>

                <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id={props.title} defaultChecked={props.checked} onChange={() => {props.callback(props.title)}}/>
                    <label className="custom-control-label" htmlFor={props.title}>{props.title}</label>
                </div>
            </div>
        </>
    )
};

export default Checkbox;