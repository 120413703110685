import React, { Component } from 'react';
import './sliderHome.css';
import {
    MDBContainer,
    MDBCol, MDBRow,
    MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView
} from 'mdbreact';

class SliderHome extends Component {
    public_folder = process.env.PUBLIC_URL;

    render() {
        return (
            <>
                <MDBContainer className="mb-5 pb-3 d-none d-md-block">
                    <MDBRow>
                        <MDBCol md="3"></MDBCol>
                        <MDBCol md="9">
                            <MDBCarousel
                                activeItem={1}
                                length={4}
                                showControls={true}
                                showIndicators={true}
                                mobileGesture={true}
                                interval={4000}
                                onHoverStop={false}
                                className="z-depth-1"
                            >
                                <MDBCarouselInner>
                                    <MDBCarouselItem itemId="1">
                                        <MDBView>
                                            <img
                                                className="d-block w-100 img-fluid"
                                                src={this.public_folder + "/imgs/sliderHome/slide01.jpg"}
                                                alt="First slide"
                                            />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="2">
                                        <MDBView>
                                            <img
                                                className="d-block w-100 img-fluid"
                                                src={this.public_folder + "/imgs/sliderHome/slide02.jpg"}
                                                alt="Second slide"
                                            />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="3">
                                        <MDBView>
                                            <img
                                                className="d-block w-100 img-fluid"
                                                src={this.public_folder + "/imgs/sliderHome/slide03.jpg"}
                                                alt="Third slide"
                                            />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="4">
                                        <MDBView>
                                            <img
                                                className="d-block w-100 img-fluid"
                                                src={this.public_folder + "/imgs/sliderHome/slide04.jpg"}
                                                alt="Fourth slide"
                                            />
                                        </MDBView>
                                    </MDBCarouselItem>
                                </MDBCarouselInner>
                            </MDBCarousel>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </>
        );
    }
}

export default SliderHome;