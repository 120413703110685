import React, { Component } from 'react';
import { StickyContainer } from 'react-sticky';
import {
    MDBContainer, MDBRow, MDBCol
} from "mdbreact";
import './display.css';
import CardDisplay from '../../Parts/CardDisplay/cardDisplay';


class Display extends Component {
 

    state = {
        videosSearched: this.props.videos,
        value: '',
        quality: 'SD - 480p'
    }

    handleSwitchQuality = (quality) => {
        this.setState({ quality: quality });
    }

    render() {
        return (
            <>
                <StickyContainer>
                    <MDBContainer className="pt-2">
                        {/* <div className="d-none d-md-block">
                            <Sticky bottomOffset={50} disableCompensation>
                                {({
                                    style,
                                    // the following are also available but unused in this example
                                    isSticky,
                                }) => (
                                    <header style={{ ...style, marginTop: isSticky ? 50 : 0, zIndex: 999 }}>
                                        {
                                            <>
                                                <MDBRow>
                                                    <MDBCol size="12" className="" style={{ backgroundColor: '#1c212e' }}>
                                                        <hr className="grey" />
                                                        <MDBFormInline>
                                                            <div className="md-form d-flex align-items-center justify-content-center my-0 w-100">
                                                                <MDBIcon icon="search" className="light-blue-text" />
                                                                <input className="form-control form-control-sm ml-3 w-100" type="text" placeholder={this.props.lang === 'fr' ? 'Rechercher un film' : 'Search movie'} aria-label='Search' value={this.props.searchValue} onChange={this.props.handleSearchValue} />
                                                            </div>
                                                            <p className="p-2 font-italic small grey-text">{this.props.nbMovies} {this.props.lang === 'fr' ? 'Résultats' : 'Results'}</p>
                                                        </MDBFormInline>
                                                    </MDBCol>
                                                </MDBRow>
                                            </>
                                        }
                                    </header>
                                )}
                            </Sticky>
                        </div>
                        <div className="d-block d-md-none">
                            <MDBCol size="12" className="mt-3" style={{ backgroundColor: '#1c212e' }}>
                                <MDBFormInline>
                                    <div className="md-form d-flex align-items-center justify-content-center my-0 w-100">
                                        <MDBIcon icon="search" className="light-blue-text" />
                                        <input className="form-control form-control-sm ml-3 w-100" type="text" placeholder={this.props.lang === 'fr' ? 'Rechercher un film' : 'Search movie'} aria-label='Search' value={this.props.searchValue} onChange={this.props.handleSearchValue} />
                                    </div>
                                    <p className="p-0 p-md-2 font-italic small grey-text">{this.props.nbMovies} {this.props.lang === 'fr' ? 'Résultats' : 'Results'}</p>
                                </MDBFormInline>
                            </MDBCol>
                        </div> */}


                        <MDBRow className="mt-2 mb-2 mt-md-4 mb-md-4">
                            {this.props.videos.sort((a, b) => b.id - a.id).map((video) => {
                                //const videoOrderDesc = this.props.videos.sort((a, b) => b.id - a.id);
                                //console.log(videoOrderDesc);
                                //console.log(video.categories);
                                //videoOrderDesc.map((video) => {
                                    //
                                //})
                                return (
                                    
                                    <MDBCol key={video.id} md="4" className="p-3">
                                        <CardDisplay video={video} lang={this.props.lang} handleSwitchQuality={this.handleSwitchQuality} quality={this.state.quality} />
                                    </MDBCol>
                                );
                                
                            })}
                        </MDBRow>
                    </MDBContainer>
                </StickyContainer>
            </>
        );
    }
}

export default Display;