import React, { Component } from 'react';


import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from "mdbreact";
import './footer.css';

class Footer extends Component {
    public_folder = process.env.PUBLIC_URL;
    state = {
        activeRoute: '/',
        lang: 'fr'
    }

    bool = true;

    render() {
        return (
            <footer>
                <MDBContainer className="text-white">
                    <MDBRow className="mt-3 mb-3">
                        <MDBCol md="4"></MDBCol>
                        <MDBCol md="4" className="mt-4">
                            <MDBAnimation type="flipInX" delay="0.7s" reveal>
                                <img src={this.public_folder + "/imgs/logo-cnes.svg"} className="img-fluid" style={{ 'width': '100%', maxHeight: 80 }} alt="" />
                            </MDBAnimation>
                        </MDBCol>
                        <MDBCol md="4"></MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="mt-5 mb-5 pt-2 pb-2">
                            <div className="d-block d-md-flex justify-content-center">
                                <MDBAnimation type="fadeInUp" delay="0.9s" reveal>
                                    <div className="pl-3 pr-3 col-example text-left">
                                        <a href="https://cnes.fr" target="_blank" rel="noreferrer" className="white-text">cnes.fr</a>
                                    </div>
                                </MDBAnimation>
                                <div className="pl-3 pr-3 col-example text-left">
                                    <MDBAnimation type="fadeInUp" delay="0.9s" reveal>
                                        <a href="https://aviso.altimetry.fr" target="_blank" rel="noreferrer" className="white-text">aviso.altimetry.fr</a>
                                    </MDBAnimation>
                                </div>
                                <div className="pl-3 pr-3 col-example text-left">
                                    <MDBAnimation type="fadeInUp" delay="1s" reveal>
                                        <a href="https://legos.obs-mip.fr" target="_blank" rel="noreferrer" className="white-text">legos.obs-mip.fr</a>
                                    </MDBAnimation>
                                </div>
                                <div className="pl-3 pr-3 col-example text-left">
                                    <MDBAnimation type="fadeInUp" delay="1.1s" reveal>
                                        <a href="https://cls.fr" target="_blank" rel="noreferrer" className="white-text">cls.fr</a>
                                    </MDBAnimation>
                                </div>
                                <div className="pl-3 pr-3 col-example text-left">
                                    <MDBAnimation type="fadeInUp" delay="1.2s" reveal>
                                        <a href="/credits" target="_self" className="white-text">{this.props.lang === 'fr' ? 'Crédits' : this.props.lang === 'en' ? 'Credits' : ''}</a>
                                    </MDBAnimation>
                                </div>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="mt-4 mb-4">
                            <div className="d-flex justify-content-center">
                                <MDBAnimation type="flipInX" delay="1.5s" reveal>
                                    <p className="small grey-text" style={{'letterSpacing':'1px'}}>© 2021 CNES/Mira Productions - Space Oceanography videos Platform, All Rights Reserved - Created by Mira Productions/Studio Alegoria.</p>
                                </MDBAnimation>
                            </div>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol className="mt-4 mb-4">
                            
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </footer >
        );
    }
}

export default Footer;