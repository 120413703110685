import React, { Component } from 'react';
import './App.css';
import Header from "./components/Header/header";
import CreditsPage from "./components/CreditsPage/creditsPage";
import Main from "./components/Main/main";
import Footer from "./components/Footer/footer";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import videos from "./videos.json";
import videosEn from "./videos-en.json";
import categories from "./categories.json";

class App extends Component {

    lang = 'fr';
    state = {
        lang: 'fr',
        categories: [],
        videos: videos,
        videosEn: videosEn,
        result: videos,
        searchValue: ''
    };

    componentWillMount() {
        const tmp = {
            fr: [],
            en: []
        }
        categories.fr.forEach(categorie => {
            tmp.fr.push({ title: categorie, checked: false });

        });
        categories.en.forEach(categorie => {
            tmp.en.push({ title: categorie, checked: false });

        });
        this.setState((oldState, props) => {
            return {
                categories: tmp,
                videos: videos,
                videosEn: videosEn,
                result: videos,
            }
        });
        this.checkTagedAndSearchedVideos();

    }

    checkTagedAndSearchedVideos() {
        let tmp = [];
        const tmp2 = [];
        this.setState((oldState, props) => {
            const searchValue = oldState.searchValue.split(' ').filter(elt => elt !== '').join(' ');
            const currentCategories = this.lang === 'fr' ? [...oldState.categories.fr] : [...oldState.categories.en];
            const currentVideos = this.lang === 'fr' ? [...oldState.videos] : [...oldState.videosEn];
            currentVideos.forEach((video) => {
                currentCategories.forEach((categorie) => {
                    if (categorie.checked === true &&
                        video.categories.indexOf(categorie.title) > -1 &&
                        tmp.indexOf(video) === -1) {
                        tmp.push(video);
                        return;
                    }
                });
                return;
            });
            if (tmp.length === 0) tmp = [...currentVideos];
            tmp.forEach( (video) => {
                if (video.title.toLowerCase().search(searchValue) > -1 && tmp2.indexOf(video) === -1) {
                    tmp2.push(video);
                    return ;
                }
            });
            return {
                // result: (oldState.searchValue.length > 2) ? (tmp2.length > 0 ? tmp2 : tmp) : tmp,
                result: (oldState.searchValue.length > 2) ? tmp2 : tmp,
                lang: this.lang
            }
        });
    }

    handleSetCategorie = (categories) => {
        this.setState((oldState, props) => {
            const tmp = {
                fr: oldState.lang === 'fr' ? categories : oldState.categories.fr,
                en: oldState.lang === 'en' ? categories : oldState.categories.en
            }
            return {
                categories: tmp,
            }
        });
        this.checkTagedAndSearchedVideos();
    }

    handleSearchValue = (searchValue) => {
        const value = searchValue.target.value.toLowerCase();
        this.setState((oldState, props) => {
            return {
                categories: oldState.categories,
                videos: oldState.videos,
                videosEn: oldState.videosEn,
                result: oldState.result,
                searchValue: value
            }
        });
        this.checkTagedAndSearchedVideos();
    }

    handleUpdateLang = (lang) => {
        this.lang = lang;
        this.checkTagedAndSearchedVideos();
    }
    
    render() {
        return (
            <>
                <Router >
                    <Header lang={this.state.lang} updateLang={this.handleUpdateLang} />
                    <Switch>
                        <Route path="/credits">
                            <CreditsPage lang={this.state.lang} />
                        </Route>
                        <Route path="/">
                            <Main   lang={this.state.lang} 
                                    searchValue={this.state.searchValue}
                                    handleSearchValue={this.handleSearchValue}
                                    categories={this.state.lang === 'fr' ? this.state.categories.fr : this.state.categories.en}
                                    setCategories={this.handleSetCategorie}
                                    videos={this.state.result}
                                    nbTotalVideos={this.state.lang === 'fr' ? this.state.videos.length : this.state.videosEn.length}
                                    />
                        </Route>
                    </Switch>
                    <Footer lang={this.state.lang}/>
                </Router>
            </>
        );
    }
}

export default App;
