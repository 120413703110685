import React, { Component } from 'react';
import './creditsPage.css';
import {
    MDBMask,
    MDBRow,
    MDBCol,
    MDBBtn,
    MDBView,
    MDBContainer,
    MDBAnimation
} from 'mdbreact';

class CreditsPage extends Component {
    public_folder = process.env.PUBLIC_URL;
    bool = true;
    render() {
        return (
            <MDBContainer fluid className="p-0">
                <MDBAnimation type="fadeIn">
                    <div className="bgCredits" style={{ backgroundImage: 'url(' + this.public_folder + '/imgs/bg-water-dream.jpg' }}>
                        <MDBMask pattern={6} className="flex-center flex-column text-white text-center" style={{ padding: '15vw 0', minHeight: '100vh' }}>
                            <MDBAnimation type="fadeInUp" delay="0.3s">
                                <h1 className="font-weight-bold h2-responsive mb-5">
                                    {this.props.lang === 'fr' ? 'Crédits' :
                                        'Credits'}
                                </h1>
                            </MDBAnimation>
                            <MDBContainer>
                                <MDBRow>
                                    <MDBCol size="12">
                                        <MDBAnimation type="fadeIn" delay="0.8s" duration="2s">
                                            {this.props.lang === 'fr' ?
                                                (
                                                    <>
                                                        <p className="text-center">Séquences produites par le CNES.</p>
                                                        <h5 className="text-center mt-5 mb-3 font-weight-bold">Coordination : </h5>
                                                        <p className="text-center">Nicolas Picot (CNES)  avec les contributions de : Jean-François Cretaux (CNES), Florent Lyard (LEGOS), Anny Cazenave, Benoit Meyssignac (CNES), Alexei Kouraev (LEGOS), Elena Zakharova (LEGOS), Rosemary Morrow (LEGOS), Felix Perosanz (CNES), Lofti Aouf (Météo France), Christian Jayles (CNES, Sylvain Ouillon (LEGOS), Claire Pottier (CNES), Denis Blumstein (CNES), Vinca Rosmorduc (CLS), Danielle De Staerke (CNES), Amandine Guillot (CNES), Benoit Laignel (Université Rouen), Frédérique Rémy (LEGOS), Laurent Brodeau (Ocean Next).</p>
                                                        <p className="text-center mt-5 mb-5">
                                                            Mira, productions exécutive - <a href="https://www.mira.fr" target="_blank">www.mira.fr</a> <br />
                                                            Scénario, réalisation, montage : Thierry Gentet <br />
                                                            Animations 2D/3D : Rémy Parot <br />
                                                            Illustration sonore, musique : Jean-Pierre Soulagnet <br />
                                                            Voix off française :  Stéphane Facco <br />
                                                            Voix off anglaise : Phil Wharton, <br />
                                                            Traduction :  Boyd Vincent <br />
                                                            Conception et réalisation du site : Sébastien Gentet / Studio Alegoria <br />

                                                        </p>
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        <p className="text-center">Sequences generated by CNES.</p>
                                                        <h5 className="text-center mt-5 mb-3 font-weight-bold">Coordination: </h5>
                                                        <p className="text-center">Nicolas Picot (CNES)  with contributions from: Jean-François Cretaux (CNES), Florent Lyard (LEGOS), Anny Cazenave, Benoit Meyssignac (CNES), Alexei Kouraev (LEGOS), Elena Zakharova (LEGOS), Rosemary Morrow (LEGOS), Felix Perosanz (CNES), Lofti Aouf (Météo France), Christian Jayles (CNES, Sylvain Ouillon (LEGOS), Claire Pottier (CNES), Denis Blumstein (CNES), Vinca Rosmorduc (CLS), Danielle De Staerke (CNES), Amandine Guillot (CNES), Benoit Laignel (Université Rouen), Frédérique Rémy (LEGOS), Laurent Brodeau (Ocean Next).</p>
                                                        <p className="text-center mt-5 mb-5">
                                                            Mira, productions executive - <a href="https://www.mira.fr" target="_blank">www.mira.fr</a> <br />
                                                            Screenplay, production, editing: Thierry Gentet <br />
                                                            Animations 2D/3D : Rémy Parot <br />
                                                            Sound design, music: Jean-Pierre Soulagnet <br />
                                                            French voice over:  Stéphane Facco <br />
                                                            English voice over: Phil Wharton, <br />
                                                            Translate :  Boyd Vincent <br />
                                                            Site design and production: Sébastien Gentet/Studio Alegoria <br />

                                                        </p>
                                                    </>
                                                )
                                                }
                                        </MDBAnimation>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mx-auto mt-3" style={{maxWidth:'80%'}}>
                                    <MDBCol size="3" className="d-block d-md-flex align-items-end">
                                        <img src={this.public_folder + "/imgs/logo-Aviso.png"} className="img-fluid" style={{ 'width': '100%', 'max-width': 150, maxHeight: 100, display: 'block', margin: '0 auto' }} alt="" />
                                    </MDBCol>
                                    <MDBCol size="6" className="d-block d-md-flex align-items-center">
                                        <img src={this.public_folder + "/imgs/logo-cnes.png"} className="img-fluid" style={{ 'width': '100%', 'max-width': 250, maxHeight: 100, display: 'block', margin: '0 auto' }} alt="" />
                                    </MDBCol>
                                    <MDBCol size="3" className="d-block d-md-flex align-items-end">
                                        <img src={this.public_folder + "/imgs/logoMira.png"} className="img-fluid" style={{ 'width': '100%', 'max-width': 250, maxHeight: 100, display: 'block', margin: '0 auto' }} alt="" />
                                    </MDBCol>
                                </MDBRow>
                            </MDBContainer>
                        </MDBMask>
                    </div>
                </MDBAnimation>
            </MDBContainer >
        )
    }
}

export default CreditsPage