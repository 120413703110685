import React, { Component } from 'react';
import VideoPlayer from '../../Parts/VideoPlayer/videoPlayer';
import {
    MDBContainer, MDBRow, MDBCol
} from "mdbreact";
import { Parallax } from "react-parallax";

import './videoPage.css';

class VideoPage extends Component {
    public_folder = process.env.PUBLIC_URL;
    render() {
        return (
            <>
                <MDBContainer fluid className="p-0 position-relative">
                    <Parallax bgImage={this.public_folder + '/imgs/bg-modalVideo.jpg'} strength={-30} style={{width:'100%', height:'50vh'}} className="position-absolute">
                        <MDBRow>
                            <MDBCol size="12" style={{minHeight:'50vh'}}>
                            </MDBCol>
                        </MDBRow>
                    </Parallax>
                    <div className="position-absolute" style={{background: 'linear-gradient(0deg, rgba(27,35,49,1) 0%, rgba(27,35,49,0) 63%)', width:'100vw', height:'50vh'}}></div>
                </MDBContainer>
                <MDBContainer className="p-0 mt-4">
                    <MDBRow>
                        <MDBCol size="12" className="">
                            <VideoPlayer {...this.props.videoJsOptions} handleSwitchQuality={this.props.handleSwitchQuality} />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

            </>
        );
    }
}

export default VideoPage;