import React, { Component } from 'react';
import './footer.css';
import {
    MDBContainer,
    MDBMask,
    MDBAnimation
} from 'mdbreact';

class PreFooter extends Component {
    public_folder = process.env.PUBLIC_URL;

    render() {
        return (
            <>
                <MDBAnimation type="fadeIn" delay="0s" duration="1s" reveal>
                <MDBContainer fluid className="p-0">
                    <div className="bg" style={{ backgroundImage: 'url(' + this.public_folder + '/imgs/bg-water-dream.jpg' }}>
                        <MDBMask overlay="black-light" className="flex-center justify-content-start text-white text-left p-5">
                            <div className="d-flex flex-column">
                                <MDBAnimation type="fadeInLeft" delay="1.15s" reveal>
                                    <h1 className="font-weight-bold display-3 text-left flex-column ">This is</h1>
                                </MDBAnimation>
                                <MDBAnimation type="fadeInLeft" delay="1.30s" reveal>
                                    <h1 className="font-weight-bold display-3 text-left flex-column ">Our</h1>
                                </MDBAnimation>
                                <MDBAnimation type="fadeInLeft" delay="1.45s" reveal>
                                    <h1 className="font-weight-bold display-3 text-left flex-column ">Water</h1>
                                </MDBAnimation>
                                <MDBAnimation type="fadeInLeft" delay="1.60s" reveal>
                                    <h1 className="font-weight-bold display-3 text-left flex-column ">Dream.</h1>
                                </MDBAnimation>
                            </div>
                        </MDBMask>
                    </div>
                </MDBContainer>
                </MDBAnimation>
            </>
        );
    }
}

export default PreFooter;