import React, { Component } from 'react';
import './filters.css';
import Checkbox from "../../Parts/Checkbox/checkbox";

class Filters extends Component {
    
    state = {
        categories: this.props.categories
    }

    componentWillMount() {
        this.setState( () => {
            return {
                categories: this.props.categories
            }
        });
    }
    
    handleToggleCategorie = (categorie) => {
        const tmp = this.props.categories.map( (cat) => {
            if (cat.title == categorie) {
                cat.checked = !cat.checked;
            }
            return cat;
        });
        this.setState(() =>  {
            return {
                categories: tmp
            }
        });
        this.props.setCategories(this.props.categories);
    }

    render() {
        return (
            <>
                {this.props.categories.map((categorie) => {
                    return (
                        <Checkbox 
                        key={categorie.title}
                        title={categorie.title}
                        checked={categorie.checked}
                        callback={this.handleToggleCategorie}/>
                    )
                })}
            </>
        );
    }
}

export default Filters;