import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import videojs from 'video.js'

import './videoPlayer.css';

require('@silvermine/videojs-quality-selector')(videojs);

class VideoPlayer extends Component {
    componentDidMount() {
        // instantiate video.js
        const node = this.containerNode
        this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
          });
          this.player.on('qualitySelected', () => {
            const qualitySwitch = node.querySelector('.vjs-quality-selector .vjs-menu ul.vjs-menu-content li.vjs-menu-item.vjs-selected span.vjs-menu-item-text');
            this.props.handleSwitchQuality(qualitySwitch.innerHTML);
         });
        // });
      }
    
      // destroy player on unmount
      componentWillUnmount() {
        if (this.player) {
          this.player.dispose();
        }
      }
    
      // wrap the player in a div with a `data-vjs-player` attribute
      // so videojs won't create additional wrapper in the DOM
      // see https://github.com/videojs/video.js/pull/3856
      render() {
        return (
          <div ref={node => (this.containerNode = node)} data-vjs-player className="vjs-fluid">
            <video ref={node => (this.videoNode = node)} className="video-js vjs-fluid" autoPlay/>
          </div>
        );
      }
    }

export default VideoPlayer;